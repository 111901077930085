import React from 'react'
import Components from '../components/_components.js'
import SbEditable from 'storyblok-react'
import config from '../../gatsby-config'

import Layout from '../layout'

let sbConfigs = config.plugins.filter((item) => {
	return item.resolve === 'gatsby-source-storyblok'
})
let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}

const loadStoryblokBridge = function (cb) {
	let script = document.createElement('script')
	script.type = 'text/javascript'
	script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
	script.onload = cb
	document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function (val) {
	var result = ''
	var tmp = []

	window.location.search
		.substr(1)
		.split('&')
		.forEach(function (item) {
			tmp = item.split('=')
			if (tmp[0] === val) {
				result = decodeURIComponent(tmp[1])
			}
		})

	return result
}

class StoryblokEntry extends React.Component {
	constructor(props) {
		super(props)

		this.state = { story: null, settings: null }
	}

	componentDidMount() {
		loadStoryblokBridge(() => { this.initStoryblokEvents() })
	}

	load() {
		window.storyblok.get({
			slug: getParam('path'),
			version: 'draft',
			resolve_relations: sbConfig.options.resolveRelations || []
		}, (data) => {
			this.setState({ story: data.story })
		})

		window.storyblok.get({
			slug: 'settings',
			version: 'draft',
			resolve_relations: sbConfig.options.resolveRelations || []
		}, (data) => {
			this.setState({ settings: data.story })
		})
	}

	initStoryblokEvents() {
		this.load()

		let sb = window.storyblok

		sb.on(['change', 'published'], (payload) => this.load())

		sb.on('input', (payload) => {
			if (this.state.story && payload.story.id === this.state.story.id) {
				payload.story.content = sb.addComments(payload.story.content, payload.story.id)
				this.setState({ story: payload.story })
			}
		})

		sb.pingEditor(() => {
			if (sb.inEditor) {
				sb.enterEditmode()
			}
		})
	}

	render() {
		const content = this.state.story?.content
		const settings = this.state.settings?.content

		if (!content || !settings) {
			return (<div>Loading...</div>)
		}

		return (
			<SbEditable content={content}>
				<Layout settings={settings}>
					{React.createElement(Components(content.component), { key: content._uid, blok: content, settings })}
				</Layout>
			</SbEditable>
		)
	}
}

export default StoryblokEntry
