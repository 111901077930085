module.exports = {
	siteMetadata: {
		title: 'Martina Pfister Coaching',
		siteUrl: 'https://martina-pfister.ch',
	},
	plugins: [
		{
			resolve: 'gatsby-source-storyblok',
			options: {
				accessToken: 'zGFq7pQom2orqyIvOQTG3gtt',
				resolveRelations: ['blogTeaser.items'],
				includeLinks: true,
				homeSlug: 'home',
				version: process.env.NODE_ENV === 'production' ? 'published' : 'draft'
			}
		},
		'gatsby-plugin-react-helmet',
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				name: `images`,
				path: `${__dirname}/src/images`,
			},
		},
		`gatsby-plugin-styled-components`,
		{
			resolve: '@slixites/gatsby-plugin-google-fonts',
			options: {
				fonts: [
					`open sans:300,400,600`,
					`sacramento`,
				],
				display: 'swap',
				preconnect: true,
				attributes: {
					rel: 'stylesheet preload prefetch',
					as: 'style',
				},
			},
		},
		{
			resolve: 'gatsby-plugin-sitemap',
			options: {
				exclude: ['/editor']
			}
		}
	],
}
